(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const flashMessages = document.querySelectorAll('.flash-message');
        flashMessages.forEach(function (message) {
            setTimeout(function () {
                message.classList.add('fade-out'); // フェードアウトクラスを追加
                message.addEventListener('animationend', function () {
                    message.remove(); // アニメーション終了後に要素を削除
                });
            }, 1500);
        });
    });
})();