// favorite_action.js
document.addEventListener('DOMContentLoaded', () => {
  const favoriteButton = document.querySelector('.favorite-button');

  if (!favoriteButton) return;

  favoriteButton.addEventListener('click', async (e) => {
    const characterId = favoriteButton.dataset.characterId;
    const favoriteId = favoriteButton.dataset.favoriteId;
    const isFavorited = favoriteButton.dataset.favoriteStatus === 'true';

    try {
      if (isFavorited) {
        // お気に入り解除
        const response = await fetch(`/favorites/${favoriteId}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const button = favoriteButton.querySelector('button');
          button.className = 'favorite-btn';
          button.textContent = 'お気に入り';
          favoriteButton.dataset.favoriteStatus = 'false';
          favoriteButton.dataset.favoriteId = '';
        }
      } else {
        // お気に入り登録
        const response = await fetch('/favorites', {
          method: 'POST',
          headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ org_character_id: characterId })
        });

        if (response.ok) {
          const data = await response.json();
          const button = favoriteButton.querySelector('button');
          button.className = 'unfavorite-btn';
          button.textContent = 'お気に入り済み';
          favoriteButton.dataset.favoriteStatus = 'true';
          favoriteButton.dataset.favoriteId = data.id;
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  });
});