(function () {
  document.addEventListener('DOMContentLoaded', () => {
    const avatarInput = document.querySelector('input[type="file"][name="user[avatar]"]');
    const removeAvatarFlag = document.getElementById('remove-avatar-flag');
    const deleteButton = document.getElementById('avatar-delete');

    if (!avatarInput) return;

    // 削除ボタンのイベントリスナー
    if (deleteButton) {
      deleteButton.addEventListener('click', () => {
        const previewContainer = document.querySelector('.c-form__avatar-preview');
        const existingPreview = document.getElementById('avatar-preview');

        if (existingPreview) {
          existingPreview.remove();
          deleteButton.remove();
        }

        const placeholder = document.createElement('div');
        placeholder.id = 'avatar-placeholder';
        placeholder.className = 'c-form__avatar-placeholder';
        placeholder.innerHTML = '<i class="fas fa-user"></i>';
        previewContainer.appendChild(placeholder);

        // 削除フラグを設定
        if (removeAvatarFlag) {
          removeAvatarFlag.value = "1";
        }

        // ファイル入力をクリア
        avatarInput.value = '';
      });
    }

    avatarInput.addEventListener('change', (e) => {
      const file = e.target.files[0];
      if (!file) return;

      // ファイルサイズチェック
      const maxSize = e.target.dataset.maxFileSize;
      if (file.size > maxSize) {
        alert('ファイルサイズが大きすぎます。5MB以下の画像を選択してください。');
        e.target.value = '';
        return;
      }

      // プレビュー表示
      const reader = new FileReader();
      const previewContainer = document.querySelector('.c-form__avatar-preview');
      const placeholder = document.getElementById('avatar-placeholder');
      const existingPreview = document.getElementById('avatar-preview');
      const existingDeleteButton = document.getElementById('avatar-delete');

      if (!previewContainer) return;

      reader.onload = (e) => {
        // 削除フラグをリセット
        if (removeAvatarFlag) {
          removeAvatarFlag.value = "0";
        }

        if (placeholder) {
          placeholder.remove();
        }

        if (existingPreview) {
          existingPreview.src = e.target.result;
        } else {
          const img = document.createElement('img');
          img.src = e.target.result;
          img.id = 'avatar-preview';
          img.className = 'c-form__avatar-image';
          previewContainer.appendChild(img);
        }

        // 削除ボタンがなければ追加
        if (!existingDeleteButton) {
          const deleteBtn = document.createElement('button');
          deleteBtn.type = 'button';
          deleteBtn.id = 'avatar-delete';
          deleteBtn.className = 'c-form__avatar-delete';
          deleteBtn.textContent = '×';

          deleteBtn.addEventListener('click', () => {
            img.remove();
            deleteBtn.remove();

            const placeholder = document.createElement('div');
            placeholder.id = 'avatar-placeholder';
            placeholder.className = 'c-form__avatar-placeholder';
            placeholder.innerHTML = '<i class="fas fa-user"></i>';
            previewContainer.appendChild(placeholder);

            if (removeAvatarFlag) {
              removeAvatarFlag.value = "1";
            }

            avatarInput.value = '';
          });

          previewContainer.appendChild(deleteBtn);
        }
      };

      reader.readAsDataURL(file);
    });
  });
})(); 