(function () {
  document.addEventListener('DOMContentLoaded', async function () {
    const prefectureSelect = document.getElementById('prefecture-select');
    const citySelect = document.getElementById('city-select');

    if (prefectureSelect && citySelect) {
      // 初期データの読み込み
      const initialPrefectureId = prefectureSelect.value;
      if (initialPrefectureId) {
        try {
          const response = await fetch(`/api/v1/cities?prefecture_id=${initialPrefectureId}`, {
            headers: {
              'Accept': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const cities = await response.json();
          updateCitySelect(cities);

          // 既存の city_id があれば選択状態を復元
          const currentCityId = citySelect.getAttribute('data-current-city-id');
          if (currentCityId) {
            citySelect.value = currentCityId;
          }
        } catch (error) {
          console.error('Error fetching initial cities:', error);
          resetCitySelect();
        }
      }

      // 都道府県選択時のイベント
      prefectureSelect.addEventListener('change', async function (e) {
        const prefectureId = e.target.value;

        if (!prefectureId) {
          resetCitySelect();
          return;
        }

        try {
          const response = await fetch(`/api/v1/cities?prefecture_id=${prefectureId}`, {
            headers: {
              'Accept': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const cities = await response.json();
          updateCitySelect(cities);
        } catch (error) {
          console.error('Error fetching cities:', error);
          resetCitySelect();
        }
      });

      // 市区町村選択肢を更新する関数
      function updateCitySelect(cities) {
        citySelect.innerHTML = '<option value="">選択してください</option>';
        citySelect.disabled = false;

        cities.forEach(city => {
          const option = document.createElement('option');
          option.value = city.id;
          option.textContent = city.name;
          citySelect.appendChild(option);
        });
      }

      // 市区町村選択肢をリセットする関数
      function resetCitySelect() {
        citySelect.innerHTML = '<option value="">都道府県を選択してください</option>';
        citySelect.disabled = true;
      }
    }
  });
})();