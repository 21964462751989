// 即時実行関数として実装
(function() {
  document.addEventListener('DOMContentLoaded', function() {
    const menuIcon = document.querySelector('.header__menu-icon');
    const menuDropdown = document.querySelector('.header__menu-dropdown');

    if (menuIcon && menuDropdown) {
      // メニューアイコンのクリックイベント
      menuIcon.addEventListener('click', (e) => {
        e.stopPropagation();
        menuDropdown.classList.toggle('is-active');
      });

      // ドキュメント全体のクリックイベント（メニュー以外をクリックした時に閉じる）
      document.addEventListener('click', (e) => {
        if (!menuIcon.contains(e.target)) {
          menuDropdown.classList.remove('is-active');
        }
      });
    }
  });
})(); 